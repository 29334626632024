import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
import Button from "components/Button";
import { HiDocumentDownload } from "react-icons/hi";
import toast from "react-hot-toast";

const Torg12: React.FC = observer(() => {
  const {
    orderStore: {
      getTorg12List,
      signTorg12,
      torg12List,
      downloadTorg12,
      setTorg12List,
    },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [labelLoading, setLabelLoading] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<string>(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    setLoading(true);
    if (info) {
      getTorg12List(info?.id, info?.id, startDate, endDate).then(() => {
        setLoading(false);
      });
    }
  }, [getTorg12List, startDate, endDate, info]);

  return (
    <>
      <div className={styles.dates}>
        <div>
          <div>Период изменений</div>

          <div>
            от:
            {
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            }
          </div>

          <div>
            до:
            {
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            }
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={loading} size={50} />
        </div>
      ) : (
        <Table striped hover>
          <thead>
            <tr>
              <th>Номер документа</th>
              <th>ID заказа</th>
              <th>Дата создания</th>
              <th>Название продукта</th>
              <th>Склад</th>
              <th>Продавец</th>
              <th>Покупатель</th>
              <th>Количество</th>
              <th>Цена</th>
              <th>НДС</th>
              <th>Итого цена</th>
              <th>Итого НДС</th>
              <th>Цена упаковки продукта</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {torg12List &&
              torg12List.map((item, index) => (
                <tr key={index}>
                  <td>{item.documentNm}</td>
                  <td>{item.orderID}</td>
                  <td>{moment(item.creationDate).format("DD.MM.YYYY")}</td>
                  <td>
                    <a
                      href={`/warehouse/product/${item.productID}`}
                      target="_blank"
                      className={styles.cardTitle}
                    >
                      {item.productTitle}
                    </a>
                  </td>
                  <td>{item.mpWarehouseName}</td>
                  <td>{item.seller}</td>
                  <td>{item.buyer}</td>
                  <td>{item.productAmount}</td>
                  <td>{item.productPrice}</td>
                  <td>{item.productVat}</td>
                  <td>{item.totalPrice}</td>
                  <td>{item.totalVat}</td>
                  <td>{item.productDeliveryPrice}</td>
                  <td className={styles.packageLabel}>
                    <div
                      style={{
                        cursor: labelLoading ? "progress" : "pointer",
                      }}
                      onClick={() => {
                        setLabelLoading(true);

                        downloadTorg12(item.documentNm).then((i) => {
                          if (!i) {
                            toast.error("Этикетка еще не готова.");
                          }
                          setLabelLoading(false);
                        });
                      }}
                      className={styles.packageLabelA}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Скачать</Tooltip>}
                      >
                        <div>
                          <HiDocumentDownload size={38} />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>
                    <Button
                      className={styles.payTbButton}
                      disabled={
                        (info?.id === item.sellerID && item.sellerSign) ||
                        (info?.id === item.buyerID && item.buyerSign)
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (info?.id) {
                          signTorg12(info?.id, [item.documentNm]).then((i) => {
                            if (i) {
                              setTorg12List(
                                torg12List.map((i) => {
                                  if (i.documentNm === item.documentNm) {
                                    return {
                                      ...i,
                                      sellerSign:
                                        info.id === i.sellerID ? "true" : null,
                                      buyerSign:
                                        info.id === i.buyerID ? "true" : null,
                                    };
                                  } else {
                                    return i;
                                  }
                                })
                              );
                            }
                          });
                        }
                      }}
                    >
                      {(info?.id === item.sellerID && item.sellerSign) ||
                      (info?.id === item.buyerID && item.buyerSign)
                        ? "Подписано"
                        : "Подписать"}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
});
export default Torg12;
