import { Tab, Tabs } from "react-bootstrap";
import React from "react";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import PaymentBills from "./PaymentBills";
import Torg12 from "./Torg12";

const InvoicesPage: React.FC = observer(() => {
  return (
    <div className={styles.root}>
      <p className={styles.title}>Накладные</p>
      <div className={styles.wrapper}>
        <Tabs
          defaultActiveKey="torg12"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="torg12" title="Торг 12">
            <Torg12 />
          </Tab>
          <Tab eventKey="paymentBills" title="Оплата счетов">
            <PaymentBills />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
});
export default InvoicesPage;
